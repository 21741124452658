<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="top">
      <div class="ban">
        <img v-if="src" :src="src" alt="" />
      </div>
      <div
        class="topbox d-flex flex-lg-row flex-row"
        style="background-color: rgb(39, 6, 69, 0.7)"
      >
        <div class="img" v-if="src && !isMobile">
          <img  :src="src" alt="" />
        </div>
        <div class="right d-flex flex-column">
          <div class="box" v-if="src">
            <!-- <div class="toMarket" v-if="this.collectSource != 2 &&this.collectSource != 1&&this.collectSource != 5 && this.ownerName" @click="toMarket">To the market collection  ></div> -->
            <div class="ava d-flex flex-row justify-space-between">
              <div class="d-flex flex-row">
                <Avatar
                  :did="collectItem.owner"
                  :size="51"
                  :hash="collectItem.profilePhoto"
                  imgType="middlev"
                  :grade="collectItem.grade"
                  showAvatarInfo
                />
                <div class="d-flex flex-column ml-7 ml-lg-4 createdby">
                  <div class="nameBy">{{ $t("created_by") }}</div>
                  <div class="name">
                    {{
                      collectItem.userName
                        ? collectItem.userName
                        : collectItem.owner.substr(0, 12) +
                          "..." +
                          collectItem.owner.substr(-12)
                    }}
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row nftcount"  v-if="!isMobile">
                <div class="sub">NFTs</div>
                <div class="num ml-2">{{ amount }}</div>
              </div>
            </div>
            <div class="desc mt-4">
              {{ collectItem.name }}
            </div>
            <img
              class="contract"
              width="20px"
              height="20px"
              src="@/assets/icons/icon_contract_v.png"
              alt=""
              @click="contractApprove"
              @mouseenter="mousedcontract()"
              @mouseleave="leavecontract()"
              v-if="
                collectItem.owner == this.$store.state.did &&
                this.collectSource == 5 && collectItem.disabled == 0   
              "
            />
              <img
              class="contract"
              width="20px"
              height="20px"
              src="@/assets/icons/icon_contract_v1.png"
              alt=""
              @click="contractHaveApply"
              @mouseenter="mousedcontract()"
              @mouseleave="leavecontract()"
              v-else-if="collectItem.owner == this.$store.state.did &&
                this.collectSource == 5 && (  collectItem.disabled == 5 ||  collectItem.disabled == 6  ) "
            />
            <img
              class="message"
              width="20px"
              height="20px"
              src="@/assets/icons/btn_message_w.png"
              alt=""
              @click="msgBtn"
              v-if="collectItem.owner != this.$store.state.did"
            />
            <img
              class="message"
              width="20px"
              height="20px"
              src="@/assets/icons/icon_edit_w.png"
              alt=""
              @click="editCollection"
              @mouseenter="moused()"
              @mouseleave="leave()"
              v-else-if="
                collectItem.owner == this.$store.state.did &&
                this.collectSource == 5
              "
            />
            <div class="explain d-flex flex-row align-center" v-if="isshow">
              <span>{{ $t("edit_collection") }}</span>
            </div>
            <img
              class="complaint"
              @click.stop="mousedComplaint()"
              v-if="
                this.$store.state.did &&
                collectItem.owner != this.$store.state.did &&
                collectItem.contractStatus != 2 &&
                collectSource != 5
              "
              src="@/assets/icons/icon_complaint_w.png"
              width="20px"
              height="20px"
              alt=""
            />
            <img
              class="complaint"
              v-if="
                (collectItem.disabled == 2 || collectItem.disabled == 3 ||
                  collectItem.disabled == 4) &&
                collectItem.owner == this.$store.state.did
              "
              src="@/assets/icons/ban.png"
              width="20px"
              height="20px"
              alt=""
              @click="AppealBtn"
              @mouseenter="mousedAppeal()"
              @mouseleave="leaveAppeal()"
            />
            <img
              class="complaint"
              v-if="
                (collectItem.disabled == 2 || collectItem.disabled == 3 ||
                  collectItem.disabled == 4) &&
                collectItem.owner != this.$store.state.did
              "
              src="@/assets/icons/ban.png"
              width="20px"
              height="20px"
              alt=""
              @mouseenter="mousedAppeal()"
              @mouseleave="leaveAppeal()"
            />
            <div
              class="explains "
              v-if="isshowComplaint"
            >
              <div class="hideNfts" @click="hideNfts('collect')">{{$t('complain_collect')}}</div>
              <div class="hideNfts" @click="hideCreator('creator')">{{$t('complain_creator')}}</div>
              <div class="hideNfts" @click="nftPlaintBtn">{{$t('popupCollectComplaintsNFTComplaint')}}</div>
            </div>
            <div
              class="contractexplain d-flex flex-row align-center"
              v-if="isshowcontract"
            >
              <span>{{ $t("apply_certification") }}</span>
            </div>
            <div
              class="appealexplain d-flex flex-row align-center"
              v-if="isshowAppeal"
            >
              <span>{{ $t("restricted_nft") }}</span>
            </div>
            <div class="nfttype" v-if="collectSource == 5">
              <div class="type">{{ 721 }}</div>
            </div>
            <img
              class="icon_collect"
              src="@/assets/icons/icon_collection.png"
              width="20px"
              height="20px"
              alt=""
              @mouseenter="mousedCollect()"
              @mouseleave="leaveCollect()"
            />
            <div
              class="explainCollect d-flex flex-row align-center"
              v-if="isshowCollect"
            >
              <span>{{ $t("nft_explain") }}</span>
            </div>
            <div class="rb_icon d-flex justify-end">
              <img
                class=""
                src="@/assets/icons/icon_share_w.png"
                width="22px"
                height="22px"
                alt=""
                style="cursor: pointer"
                @click.stop="shareBtn"
              />
              <div class="share" v-if="openShare">
                <div
                  class="d-flex flex-row mt-lg-5 mt-5 pointer"
                  style="cursor: pointer"
                  :class="{ click: isCopy }"
                  @click="share('copyLink', '')"
                >
                  <img
                    class="mx-5"
                    src="@/assets/icons/icon_IPFS.png"
                    alt="Copy Link"
                  />
                  <span class="ml-0">
                    {{ $t("carddetailShareCopyLink") }}
                  </span>
                </div>
                <!-- <div class="d-flex flex-row mt-lg-6 mt-6 pointer " @click="toTweet"> -->
                <div
                  class="d-flex flex-row mt-lg-6 mt-6 pointer"
                  @click="share('twitter', sharePath)"
                  style="cursor: pointer"
                >
                  <img
                    class="mx-5"
                    src="@/assets/icons/icon_twitter.png"
                    alt="Tweet"
                  />
                  <span class="ml-0">Twitter</span>
                </div>
                <div
                  class="d-flex flex-row mt-lg-6 mb-lg-4 mt-6 mb-4 pointer"
                  @click="share('facebook', sharePath)"
                  style="cursor: pointer"
                >
                  <v-icon
                    class="mx-lg-5 ml-5 icon mx-5"
                    color="primary"
                    style="margin-top: -4px"
                  >
                    mdi-facebook
                  </v-icon>
                  <span class="ml-0 pr-7">Facebook</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tit">
      <p v-html="collectItem.description"></p>
    </div>
    <div class="cards-container">
      <div class="search_create">
        <div class="clearmode box d-flex flex-row">
          <!-- <div class="checkbox" v-if="typeStatus == 'createorPage' && !isMobile">
						<v-checkbox v-model="checkbox" @click="checkBoxClick(checkbox)"></v-checkbox>
					</div>
					<div class="content" v-if="typeStatus == 'createorPage' && !isMobile">
						{{ $t("Clean Mode") }}
					</div> -->
          <div class="image" v-if="typeStatus == 'createorPage'">
            <div class="top_tab mb-4">
              <span
                class="btn mr-8"
                :class="{ btn2: ownerClick }"
                v-if="ownerClick && isShowCheckbox == 'isCheck'"
                @click="mode_click('owned')"
                >{{ $t("Owned") }}</span
              >
              <span
                class="btn mr-8"
                :class="{ btn2: ownerClick }"
                v-else-if="isShowCheckbox != 'isCheck'"
                @click="mode_click('owned')"
                >{{ $t("Owned") }}</span
              >
              <span
                class="btn mr-8"
                :class="{ btn2: soldClick }"
                v-if="soldClick && isShowCheckbox == 'isCheck'"
                @click="mode_click('sold')"
                >{{ $t("Sold") }}</span
              >
              <span
                class="btn mr-8"
                :class="{ btn2: soldClick }"
                v-if="isShowCheckbox != 'isCheck'"
                @click="mode_click('sold')"
                >{{ $t("Sold") }}</span
              >
            </div>
          </div>
          <CollectionInput
            style="width: 270px; margin-bottom: 20px"
            v-if="!isMobile && isShowCheckbox != 'isCheck'"
            @change="searchChange"
          />
          
        </div>

        <div class="create d-flex flex-row">
          <div class="d-flex flex-row buttons">
            <button
              class="subOnsale mr-3 "
              rounded
              v-if="typeStatus == 'createorPage' && isShowCheckbox != 'isCheck'"
              @click="onSale"
            >
              <div class="titleName">{{ $t("putonsale") }}</div>
            </button>
            <button
              class="subOnsale mr-3 "
              rounded
              v-if="typeStatus == 'createorPage' && isShowCheckbox != 'isCheck'"
              @click="endSale"
            >
              <div class="titleoffSale">{{ $t("endSale") }}</div>
            </button>
            <button
              class="subOnsale mr-3 "
              rounded
              v-if="typeStatus == 'createorPage' && isShowCheckbox != 'isCheck' &&  collectItem.owner == this.$store.state.did"
              @click="onDestroy"
            >
              <div class="titleburn">{{ $t("burn") }}</div>
            </button>
          </div>
          <div v-if="collectItem.owner != this.$store.state.did">
            <div
              class="owner"
              v-if="
                this.collectSource != 2 &&
                this.collectSource != 1 &&
                this.ownerName &&  this.owerfrom == 'createCollect'
              "
            >
              {{ $t("owner_by") }}
              <span
                class="ml-1"
                style="cursor: pointer"
                @mouseenter="mousedOwner()"
                >{{ ownerName }}</span
              >
            </div>
            <div
              style="
                position: absolute;
                margin-left: -250px;
                margin-top: 5px;
                z-index: 1;
              "
            >
              <CreatorAvator :did="ownerBy" v-if="isShowAva" />
            </div>
          </div>
          <v-btn
            v-if="
              typeStatus == 'createorPage' &&
              collectItem.owner == this.$store.state.did &&
              isShowCheckbox != 'isCheck'
            "
            class="uc createnft mb-3"
            width="150px"
            height="41px"
            rounded
            @click="createNft"
            >+ {{ $t("create_nft") }}</v-btn
          >
          <SortSelect
            v-if="typeStatus == 'createorPage' && isShowCheckbox != 'isCheck'"
            class="mr-1 dot mt-lg-1 ml-8"
            :list="selectList"
            v-model="activeSort"
          />
          <img
            v-if="typeStatus == 'createorPage' && isShowCheckbox != 'isCheck'"
            :class="[rotate ? 'go' : '']"
            class="refresh"
            src="@/assets/icons/icon_refresh.png"
            @click="refresh"
          />
        </div>
      </div>

      <div class="cardlist">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div v-if="typeStatus == 'createorPage'" class="d-flex flex-wrap card">
          <div v-for="(item, i) in list" :key="i">
            <Card
              v-scroll="onScroll"
              :type="showModel == 'owned' ? 'clearmode' : cardType"
              :value="item"
              owerfrom="my"
              sourcemarket='collectMarket'
            />
            <div>
              <v-checkbox
                v-if="isShowCheckbox == 'isCheck' && showModel == 'sold'"
                class="check"
                color="#270645"
                :key="i"
                :value="item.checked"
                @click="childcheckboxChange(i, item)"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-else-if="isShowCheckbox == 'isCheck' && showModel == 'owned'"
                class="ownerCheck"
                color="#270645"
                :key="i"
                :value="item.checked"
                @click="childcheckboxChange(i, item)"
                hide-details
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div v-else class="d-flex flex-wrap card">
          <Card
            v-for="(item, i) in list"
            :key="i"
            v-scroll="onScroll"
            :type="cardType"
            :value="item"
            :owerfrom="owerfrom"
          />
        </div>
            
      </div>
      <loading :isShowLoading="isShowLoading"></loading>
    </div>
    <div
      v-if="isShowCheckbox == 'isCheck'"
      class="totalbox d-flex justify-end align-center"
    >
      <v-checkbox
        class="check mt-2"
        color="#ffffff"
        :label="label"
        hide-details
        @click="checkboxChange"
        :value="checkAllCard"
      ></v-checkbox>
      <p>{{ $t("nft_number") }}：{{ totalNumber }}</p>
      <div class="btnbox mr-5">
        <button class="subcancel" @click="subCancel">
          {{ $t("orderActionCancel") }}
        </button>
      </div>
      <button class="subsure" @click="subSure">{{ $t("countersign") }}</button>
    </div>
    <div class="right_bottom" v-if="!isMobile">
      <img src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
    </div>
    <div
      v-if="openmsg"
      style="position: fixed; right: 20px; bottom: 20px; z-index: 300"
    >
      <message @getOpen="OpenMsg" :currfriend="currfriend"></message>
    </div>
    <v-dialog v-model="opencard">
      <carousel
        class="otherpage"
        :list="list"
        v-if="opencard"
        @getOpen="OpenCard"
      ></carousel>
    </v-dialog>
    <v-dialog v-model="openCreateCollection" v-if="openCreateCollection">
      <createCollection
        @getOpen="OpenCreateCollection"
        :createType="createType"
        :NFTInfo="NFTInfo"
      ></createCollection>
    </v-dialog>
    <!-- 投诉 -->
    <v-dialog v-model="nftComplaint">
      <nft-complaints
        :Nft_sku="collectItem"
        complaintType="commonComplaint"
        @getOpen="openNftcComp"
      ></nft-complaints>
    </v-dialog>
    <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt" @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
    <!-- 申诉 -->
    <v-dialog v-model="Appeal">
      <nftAppeal
        :parentValue="collectItem"
        appealType="collectAppeal"
        @getOpen="Open"
      >
      </nftAppeal>
    </v-dialog>

    <!-- 开始售卖 -->
    <v-dialog v-model="openputonsale">
      <market
        :address="collectItem.collectItem"
        :onSaleTokenIds="onSaleTokenIds"
        :offsaleTokenids="offSaleTokenIds"
        :NFTAmount="NFTAmount"
        :nftAddresss="nftAddresss"
        :supplyLimit="supplyLimit"
        :parentValue="collectItem.assetListPage.list[0]"
        :name="collectItem.name"
        onsaleType="multi"
        Type="fixedPrice"
        v-bind="$attrs"
        v-on="$listeners"
        v-if="openputonsale"
        @getOpen="openMarket"
      ></market>
    </v-dialog>

    <!-- <v-dialog v-model="openputonsale">
      <put-on-sale
	   :address="collectItem.collectItem"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
		:NFTAmount="NFTAmount"
		:nftAddresss="nftAddresss"
        :supplyLimit="supplyLimit"
        :name="collectItem.name"
        :parentValue="collectItem"
		onsaleType="multi"
        @getOpen="openPutOnSale"	
      ></put-on-sale>
    </v-dialog> -->
    <!-- 结束售卖 -->
    <v-dialog v-model="Sold" v-if="Sold">
      <soldout
        :address="collectItem.collectItem"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :name="collectItem.name"
        offsaleType="multi"
        :parentValue="collectItem.assetListPage.list[0]"
        :marketAddress="marketAddress"
        @getOpen="openSold"
      ></soldout>
    </v-dialog>
    <!-- 销毁 -->
    <v-dialog v-model="Burning" v-if="Burning">
      <burn
        :pageValue="collectItem.assetListPage.list[0]"
        :address="collectItem.collectItem"
        :offSaleTokenIds="offSaleTokenIds"
        offsaleType="multi"
        @getOpen="openBurn"
      ></burn>
    </v-dialog>
    <v-dialog v-model="contractOpen" v-if="contractOpen">
      <contractApply
        :Nft_sku="collectItem"
        @getOpen="openContract"
      ></contractApply>
    </v-dialog>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import Avatar from "@/components/avatar/index.vue";
import Carousel from "../../components/carousel";
import message from "@/components/popup/message.vue";
import CollectionInput from "@/components/CollectionInput.vue";
import Card from "@/components/workCard/Card.vue";
// import SortSelect from "@/components/SortSelect.vue";
import PutOnSale from "@/components/popup/putOnSale.vue";
import CreateCollection from "@/components/popup/createCollection";
import SortSelect from "@/components/collectSortSlect.vue";
import nftComplaints from "@/components/popup/nftComplaints.vue";
import Banprompt from "@/components/popup/banprompt.vue";
import NftAppeal from "@/components/popup/nftAppeal.vue";
import Soldout from "@/components/popup/soldout.vue";
import Burn from "@/components/popup/burn.vue";
import Market from "@/components/popup/market.vue";
import ContractApply from "@/components/popup/contractApply.vue";

import { mapState } from "vuex";
import api from "@/api";
import { getFileSrc } from "@/utils/file";
import { share } from "@/utils";
import { getSettingArr } from "@/utils/helper";
import CreatorAvator from "@/components/creatorAvatar/index";
import Loading from "@/components/loading.vue";
const COLLECTION_KEY = "COLLECTION";
let last_time = 0;

export default {
  name: "CollectionPage",
  components: {
    Market,
    Carousel,
    message,
    Avatar,
    CollectionInput,
    Card,
    SortSelect,
    PutOnSale,
    CreatorAvator,
    CreateCollection,
    NftAppeal,
    nftComplaints,
    Banprompt,
    Soldout,
    Burn,
    ContractApply,
    Loading
  },
  data: function () {
    return {
      marketAddress: "",
      Burning: false,
      contractOpen: false,
      nftAddresss: [],
      type: "",
      isShowCheckbox: "",
      nfType: "",
      Banprompt: false,
      openCreateCollection: false,
      opencard: false,
      open: false,
      openmsg: false,
      offset: true,
      pageInfo: {},
      did: "",
      currfriend: {},
      list: [],
      sku: {},
      typeStatus: "",
      showRightBottom: false,
      isShowLoading: false,
      isCopy: false,
      imgUrl: "",
      src: "",
      saleStatus: "",
      pageNumber: 1,
      pageSize: 20,
      ownerName: "",
      isshow: false,
      Sold: false,
      isshowComplaint: false,
      isshowcontract: false,
      isshowCollect: false,
      isshowAppeal: false,
      nftComplaint: false,
      selectList: [
        {
          value: "0",
          label: this.$t("market_filter_all"),
        },
        {
          value: "1",
          label: this.$t("otherpageTabsPageOnsale"),
        },
        {
          value: "3",
          label: this.$t("collection_tag_notonsale"),
        },
        {
          value: "4",
          label: this.$t("token_state_end"),
        },
      ],
      activeSort: "0",
      checkAllCard: false,
      isClick: true,
      isChange: false,
      showAllCheck: false,
      openShare: false,
      sharePath: "",
      totalNumber: 0,
      openputonsale: false,
      address: "",
      constructID: "",
      name: "",
      onSaleTokenIds: [],
      offSaleTokenIds: [],
      NFTAmount: [],
      NFTlist: [],
      supplyLimit: 2,
      collectItem: {},
      userData: {},
      url: "",
      empty: false,
      amount: 0,
      checkbox: false,
      cardType: "",
      collectSource: "",
      owerfrom: "firspublish",
      isShowAva: false,
      ownerBy: "",
      rotate: false,
      NFTInfo: {
        collectionImgUrl: "",
        name: "",
        linkUrl: "",
        description: "",
        collectionMetadata: "",
        id: "",
        totalPage: "",
        loading: false,
        searchInput: "",
        createType: "",
        category: "",
        nftAddress: "",
        contractCollectionId:''
      },
      showModel: "owned",
      soldClick: true,
      ownerClick: false,
      Appeal: false,
    };
  },
  props: {
    collectfrom: {
      type: String,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    handler: function () {
      return {
        activeSort: this.activeSort,
      };
    },
    ...mapState({
      searchInput: (state) => state.searchInput,
    }),
  },
  watch: {
    handler: {
      handler: function () {
        this.list = [];
        this.pageNumber = 1;
        this.getColleectList();
      },
      deep: true,
    },
  },
  async mounted() {
    this.label = this.$t("Select_All");
    this.list.forEach((ele) => {
      ele.checked = false;
    });

    this.showNoRead();
    window.addEventListener("click", this.clickOther);
    window.eventBus.$on("LangChange", this.onLangChange);
    window.eventBus.$on("collectionEdit", this.collectionEdit);
      window.eventBus.$on('contractApply', this.contractApply);
      this.init();
  },
  methods: {
    share,
    searchChange(val) {
      this.$gtag.event('集合主页-搜索', { 'event_category': 'Click', 'event_label': 'search' })
      this.searchInput = val;
      clearTimeout(last_time);
      last_time = setTimeout(() => {
        this.list = [];
        this.pageNumber = 1;
        this.getColleectList();
      }, 600);
    },
   async init(){
        let collection = this.$route.path;
    this.url = this.$route.params.id;

    if (this.$route.params.source == "firspublish") {
      this.collectSource = 1;
      this.owerfrom = "firspublish";
    } else if (this.$route.params.source == "detaillink") {
      this.collectSource = 2;
      this.owerfrom = "detaillink";
    } else if (this.$route.params.source == "otherpage") {
      this.collectSource = 5;
      this.owerfrom = "otherpage";
    } else if (this.$route.params.source == "offsale") {
      this.collectSource = 4;
      this.owerfrom = "offsale";
    } else if (this.$route.params.source == "createCollect") {
      this.collectSource = 5;
      this.owerfrom = "createCollect";
    } else {
      this.collectSource = 1;

    }
	let linkUrl = this.$route.params.id;
	    await this.getColleectList();
    // let res_info = await api.home.collectionInfo({
    //   linkUrl,
    // });
    // this.collectItem = res_info.data;
    if (this.$route.params.source == "createCollect") {
      this.mode_click("sold");
    }

    if (this.collectItem.nftType == "ERC721") {
      this.nfType = "721";
    } else {
      this.nfType = "1155";
    }
    window.eventBus.$on("collectionPay", this.collection);
    if (collection.indexOf("collection_card") != -1) {
      this.typeStatus = "cardPage";
    } else {
      if (collection.indexOf("collection_create") != -1) {
        if (this.$route.params.owner != this.$store.state.did) {
          let targetUrl = window.location.href
            .replace("collection_create", "collection")
            .replace("createCollect", "detaillink");

          location.href = targetUrl;
        }
        this.typeStatus = "createorPage";
      } else {
        this.typeStatus = "collectionPage";
      }
    }

    if (this.collectItem.description)
      this.collectItem.description = this.collectItem.description.replace(
        /\n/g,
        "<br/>"
      );

    this.src = await getFileSrc(COLLECTION_KEY, this.collectItem.imgUrl);

    let val_user = window.sessionStorage.getItem("UserData");
    this.userData = JSON.parse(val_user);
    if (this.typeStatus == "createorPage") {
      this.cardType = "my";
    } else {
      this.cardType = "other";
    }

    this.$forceUpdate();
    },
    refresh() {
      this.rotate = true;
      setTimeout(() => {
        this.rotate = false;
      }, 2000);
      this.list = [];
      this.pageNumber = 1;
      this.getColleectList();
    },
    childcheckboxChange(i, item) {
      console.log("6666666", item.checked);
      this.checkAllCard = false;
      this.list[i].checked = !this.list[i].checked;
      this.nftList = this.list.filter((item) => item.checked);
      this.totalNumber = this.nftList.length;
      console.log("wxl --- sqwwqe", this.nftList);
      if (this.totalNumber == this.list.length) {
        this.checkAllCard = true;
      }
    },
    //批量上架销售
    onSale() {
      //   this.endSaleClicked =0
      //   this.burnClicked = 0

      //  this.disonDestroy=true
      //   this.disendSale=tru
      this.activeSort = 3
      this.type = "onSale";
      this.saleStatus = 0;
      this.totalNumber = 0;
      //   this.level = ''
      this.list = [];
      this.pageNumber = 1;
      // this.collectSource = 4;
      //   this.isShowSelect = false
      this.getColleectList();
      this.isShowCheckbox = "isCheck";
      this.onsaleClicked++;
    },

    // 批量下架
    endSale() {
      //   this.onsaleClicked = 0
      //   this.burnClicked = 0
      //    this.disonDestroy=true
      //   this.disonSale=true
      this.activeSort = 15
      this.type = "endSale";
      this.saleStatus = 1;
      this.totalNumber = 0;
      // this.collectSource = 3;
      //   this.level = ''
      this.list = [];
      this.pageNumber = 1;
      //   this.isShowSelect = false
      this.getColleectList();
      this.isShowCheckbox = "isCheck";

      this.marketAddress = this.list[0].marketAddress;
      console.log("wxl -- rrrrr", this.marketAddress);
    },
    //批量销毁
    onDestroy() {
       this.collectSource = 8;
      this.type = "destroy";
      this.totalNumber = 0;
      this.saleStatus = 0;
      // this.collectSource = 8;
      //   this.level = ''
      this.list = [];
      this.pageNumber = 1;
      //   this.isShowSelect = false
      this.getColleectList();
      this.isShowCheckbox = "isCheck";
    },
    openSold(e) {
      this.Sold = e;
    },
    openBurn(e) {
      this.Burning = e;
    },
    openContract(e) {
      this.contractOpen = e;
    },
    createNft() {
      
      let imgObj = {};
      imgObj.src = this.collectItem.imgUrl;
      imgObj.name = this.collectItem.name;
      imgObj.category = this.collectItem.category;
      imgObj.contractAddress = this.collectItem.contractAddress;
      imgObj.linkUrl = this.collectItem.linkUrl;
     imgObj.contractCollectionId = this.collectItem.contractCollectionId
      let json = localStorage.getItem("key_user_grade");
      let jsonObj = JSON.parse(json);

      if (jsonObj.grade == 2) {
        this.Banprompt = true;
        console.log("wxl ---- 2222");
      } else {
        //   this.$router.push({
        // 		name: "CreateNft",
        // 		query: {
        // 			createType: this.collectItem.nftType
        // 		},
        // 	});
        this.$router.push({
          name: "Create",
          params: { imgObj: imgObj },
        });
      }
    },
    collectionEdit() {
      location.reload();
    },
    contractApply(){

      this.init();
    },
    mousedOwner() {
      this.isShowAva = true;
    },
    clickOther() {
      this.isShowAva = false;
      this.isShowOwner = false;
      this.openShare = false;
      this.isshowComplaint = false
    },
    moused() {
      console.log("wxl --- 3333");
      this.isshow = true;
    },
    leave() {
      this.isshow = false;
    },
    mousedcontract() {
      this.isshowcontract = true;
    },
    leavecontract() {
      this.isshowcontract = false;
    },
    mousedComplaint() {
      
      this.isshowComplaint = true;
    },
    leaveComplaint() {
      this.isshowComplaint = false;
    },
    mousedCollect() {
      this.isshowCollect = true;
    },
    leaveCollect() {
      this.isshowCollect = false;
    },

    mousedAppeal() {
      this.isshowAppeal = true;
    },
    leaveAppeal() {
      this.isshowAppeal = false;
    },
    toMarket() {
      // this.$router.push({
      // 	name:"Launch"
      // })
      let url;
      if (this.collectItem.linkUrl) {
        url = this.collectItem.linkUrl;
      } else {
        url = this.collectItem.address;
      }
      let collectsource = "detaillink";
      let owner = this.collectItem.owner;

      this.$router.push({
        path: `/collection/${url}/${collectsource}/${owner}`,
      });
    },
    mode_click(mode) {
      this.collectSource = 5;
      this.pageNumber = 1;
      this.list = [];
      this.showModel = mode;
      this.showStatus(mode);
      this.getColleectList();
    },
    showStatus(mode) {
      if (mode == "owned") {
        this.soldClick = false;
        this.ownerClick = true;
      } else if (mode == "sold") {
        this.soldClick = true;
        this.ownerClick = false;
      }
    },
    async getColleectList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.isShowLoading = true
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        // tokenType:
        searchKey: encodeURIComponent(this.searchInput || ""),
        // sort
        linkUrl: this.url,
        sourceType: this.collectSource,
        owner: this.$route.params.owner ? this.$route.params.owner : '',
        filterType: this.activeSort,
      };
      if (this.$route.params.source == "createCollect") {
        params.owner = this.$store.state.did;
      }
	  let res = await api.home.collectionInfo(params);
    this.collectItem = res.data;
    if( this.collectItem.owner != this.$store.state.did  && res.data.assetListPage != null ){
        this.ownerName = res.data.assetListPage.list[0].userName;
        this.ownerBy = res.data.assetListPage.list[0].nftOwner;
        if (!this.ownerName) {
          this.ownerName =
             this.ownerBy.substr(0, 6) +
            "..." +
            this.ownerBy.substr(-6);
        }

                }
   
       let assetList;
    if( res.data.assetListPage != null){
       assetList = res.data.assetListPage.list;
      this.amount = res.data.assetListPage.totalRow;
      let { totalPage } = res.data.assetListPage;
      this.totalPage = totalPage;
    }
      if (assetList != null && assetList.length > 0)
        this.list = this.list.concat(assetList); 
      if (
        (this.pageNumber == 1 || this.pageNumber == 0) &&
        this.list.length == 0
      ) {
        this.empty = true;
      } else {
        this.empty = false;
      }
      this.loading = false;
       this.isShowLoading = false
    },
    collection() {
      this.list = [];
      this.collectSource = 5;
      this.pageNumber = 1;
      this.isShowCheckbox = "";
      this.getColleectList();
    },
    onScroll(e) {
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = e.target.scrollingElement;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        this.totalPage > this.pageNumber
      ) {
        if (!this.loading) {
          this.pageNumber++;
        }
        // this.pageNumber++;
        this.getColleectList();
      }
    },
    shareBtn() {
        this.$gtag.event('集合主页-分享', { 'event_category': 'Click', 'event_label': '分享' })
      this.openShare = true;
    },
    msgBtn() {
      this.$gtag.event('集合主页-留言', { 'event_category': 'Click', 'event_label': '留言' })
      this.currfriend = {
        fansName: this.collectItem.userName,
        fansAddress: this.collectItem.owner,
      };
      this.openmsg = true;
    },
   nftPlaintBtn() {
      this.$gtag.event('集合主页-投诉', { 'event_category': 'Click', 'event_label': '投诉' })
				this.nftComplaint = true;
				this.isshowComplaint = false
			},
			hideNfts(model){
				  this.$gtag.event('集合主页-隐藏集合', { 'event_category': 'Click', 'event_label': '隐藏集合' })
         this.isshowComplaint = false
         this.hideHandle(model)
			},
			hideCreator(model){
			  this.$gtag.event('集合主页-隐藏创作者', { 'event_category': 'Click', 'event_label': '隐藏创作者' })
         this.isshowComplaint = false
         this.hideHandle(model)
      },
      async hideHandle(model){
				let params = {
					authenticationAddress:this.$store.state.did
				}
				if(model == 'collect'){
					params.blockContractCollectionId = this.collectItem.contractCollectionId
				}else{
					params.blockOwner = this.collectItem.owner
				}
				let res = await api.home.hideNfts(params)
				if(res.success){
					this.$toast("success", this.$t("success"))
				}else{
					 this.$toast("error", "Activate faild")
				}

			},
    openNftcComp(e) {
      this.nftComplaint = e;
    },
    checkboxChange() {
      this.checkAllCard = !this.checkAllCard;
      // this.showAllCheck = this.checkAllCard;
      console.log("111111111", this.showAllCheck);
      if (this.checkAllCard) {
        this.list.forEach((ele) => {
          ele.checked = true;
        });
        this.nftList = this.list.filter((item) => item.checked);
        this.totalNumber = this.nftList.length;
      } else {
        this.list.forEach((ele) => {
          ele.checked = false;
        });
      }

      this.$forceUpdate();
    },
    //申诉弹层
    AppealBtn() {
      if (this.collectItem.contractStatus == 4) {
        this.$toast("error", "Appealed");
      } else {
        this.Appeal = true;
      }
    },
    Open(e) {
      this.Appeal = e;
    },
    openBanprompt(e) {
      this.Banprompt = e;
    },

    subCancel() {
      this.list = [];
      this.pageNumber = 1;
      this.isShowCheckbox = "";
       this.activeSort = "0"
      this.collectSource = 5;
       this.openputonsale =false
          this.Burning = false
        this.Sold = false
           this.getColleectList();
     
    },

    subSure() {
      this.marketAddress = this.nftList[0].marketAddress;
      this.offSaleTokenIds = [];
      this.onSaleTokenIds = [];
      this.NFTAmount = [];
      // this.disonSale = false
      //  this.disonDestroy=false
      // this.disendSale=false
      if (this.type == "onSale") {
        if (this.totalNumber != 0) this.openputonsale = true;
      } else if (this.type == "endSale") {
        if (this.totalNumber != 0) {
          this.Sold = true;
          this.name = "";
        }
      } else if (this.type == "destroy") {
        if (this.totalNumber != 0) this.Burning = true;
      }
      for (let i = 0; i < this.nftList.length; i++) {
        this.offSaleTokenIds.push(this.nftList[i].tokenId);
        this.onSaleTokenIds.push(this.nftList[i].tokenId);
        this.nftAddresss.push(this.nftList[i].contractAddress);
        this.NFTAmount.push(this.nftList[i].amount);
      }

      console.log("wxl --- subSure ",this.offSaleTokenIds)
    },
    openMarket(e) {
      this.openputonsale = e;
    },
    editCollection() {
      this.createType = this.collectItem.nftType;
      this.NFTInfo.collectionImgUrl = this.collectItem.imgUrl;
      this.NFTInfo.name = this.collectItem.name;
      this.NFTInfo.linkUrl = this.collectItem.linkUrl
        ? this.collectItem.linkUrl
        : this.collectItem.address;
      this.NFTInfo.description = this.collectItem.description;
      this.NFTInfo.collectionMetadata = this.collectItem.collectionMetadata;
      this.NFTInfo.id = this.collectItem.id;
      this.NFTInfo.category = this.collectItem.attribute;
      this.NFTInfo.contractAddress = this.collectItem.contractAddress;
      this.NFTInfo.contractCollectionId = this.collectItem.contractCollectionId
      // this.$router.push({ name: "Create",params:{
      //  NFTInfo:this.NFTInfo
      //  } });
      this.openCreateCollection = true;
    },
    contractApprove() {
      console.log("vs");
      this.contractOpen = true;
    },
    contractHaveApply(){
       this.$toast("success",this.$t('contract_Have_Apply'))
    },
    //弹框关闭后获取open值
    OpenMsg(e) {
      this.openmsg = e;
    },
    openCarousel() {
      this.opencard = true;
    },
    OpenCard(e) {
      this.opencard = e;
    },
    OpenCreateCollection(e) {
      this.openCreateCollection = e;
    },
    toCopyLink() {
      // http://web.upticknft.com:8084/#/saledetail?id=7666
      var input = document.createElement("input");
      input.value =
        process.env.VUE_APP_BASE_URL +
        `#/saledetail?id=${this.$route.query.id}`;

      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.isCopy = true;
      setTimeout(() => (this.isCopy = false), 100);
      this.$toast("success", this.$t('copt_success'))

      //xxl TODO need to add tip
    },

    toTweet() {
      // alert("abc ...");
      var meta = document.createElement("meta");
      //meta.setAttribute('property', 'og:image');
      meta.setAttribute("name", "twitter:image:src");
      meta.setAttribute(
        "content",
        "https://cdn.cms-twdigitalassets.com/content/dam/developer-twitter/redesign-2021-images/og-social-card/devwebsite_card_tn.jpg.twimg.768.jpg"
      );
      document.getElementsByTagName("head")[0].appendChild(meta);

      //window.open("http://twitter.com/share?text=Im Sharing on Twitter&url=http://stackoverflow.com/users/2943186/youssef-subehi&hashtags=stackoverflow,example,youssefusf")
      window.open(
        "https://twitter.com/intent/tweet?text=Hello%20world&url=http://graphics8.nytimes.com/images/2012/02/19/us/19whitney-span/19whitney-span-articleLarge.jpg"
      );
      //window.open("https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fdeveloper.twitter.com%2F&amp;ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&amp;text=Guides%20%7C%20Docs%20%7C%20Twitter%20Developer%20Platform&amp;url=https%3A%2F%2Fdeveloper.twitter.com%2Fen%2Fdocs%2Ftwitter-for-websites%2Ftweet-button%2Foverview")
    },
    async showNoRead() {
      const json = localStorage.getItem("key_user");
      if (json) {
        let res2 = await api.home.isNewMessage({
          address: this.$store.state.did,
        });
        let newMsgCount = res2.data;

        // 有新消息进行弹窗
        if (newMsgCount > 0) {
          let params = {
            pageSize: 10,
            pageNumber: 1,
            owner: this.$store.state.did,
            isRead: false,
            pushTypes:getSettingArr()
          };
          let res = await api.home.sysMessage(params);
          console.log("wxl --- sysMessage", res);
          if (res.data) {
            let list = res.data.list;
            for (let index = 0; index < list.length; index++) {
              setTimeout(() => {
                this.$mtip({
                  title: list[index].pushContract,
                });
              }, 1000);
            }
          }
        }
      }
      window.eventBus.$emit("ViewMessage", true);
    },
    onLangChange() {
      this.selectList = [
        {
          value: "0",
          label: this.$t("market_filter_all"),
        },
        {
          value: "1",
          label: this.$t("otherpageTabsPageOnsale"),
        },
        {
          value: "3",
          label: this.$t("collection_tag_notonsale"),
        },
        {
          value: "4",
          label: this.$t("token_state_end"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  padding: 0;
  margin: 0;

  .top {
    max-width: 1220px;
    height: 300px;
    margin: 0 auto;
    position: relative;

    .ban {
      max-width: 1220px;
      height: 300px;
      margin: 0 auto;
      opacity: 0.8;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 15px;
      }
    }

    .topbox {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 15px;

      .img {
        width: 370px;
        height: 240px;
        margin-right: 90px;
        margin-left: 105px;

        img {
          object-fit: contain;
          display: block;
          margin-top: 30px;
          width: 100%;
          height: 100%;
          border-radius: 15px;
          // border: solid 4px #ffffff;
        }
      }

      .right {
        padding: 35px 0 25px;

        .rt_icon {
          .oneicon {
            width: 20px;
            height: 20px;
          }
        }

        .rb_icon {
          position: absolute;
          top: 30px;
          right: 30px;

          .twoicon {
            width: 22px;
            height: 26px;
          }
        }

        .message {
          position: absolute;
          cursor: pointer;
          top: 30px;
          right: 80px;
        }
        .contract {
		// pointer-events: none;
          position: absolute;
          cursor: pointer;
          top: 30px;
          right: 120px;
        }

        .complaint {
          position: absolute;
          cursor: pointer;
          top: 30px;
          right: 130px;
        }
        .icon_collect {
          position: absolute;
          cursor: pointer;
          bottom: 30px;
          right: 30px;
        }
        .nfttype {
          width: 40px;
          height: 19px;
          background-color: #ffffff;
          border-radius: 9px;
          position: absolute;
          bottom: 30px;
          right: 70px;
          text-align: center;
          .type {
            font-family: Helvetica;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 19px;
            letter-spacing: 0px;
            color: #270645;
          }
        }

        .box {
          min-width: 543px;
          // margin: auto;
          margin: 25px auto;
          .toMarket {
            cursor: pointer;
            position: absolute;
            top: -23px;
            right: 25px;
            font-family: Helvetica;

            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 100px;
            letter-spacing: 0px;
            color: #ffffff;
          }
          .ava {
            align-items: center;

            .nameBy {
              min-height: 17px;
              font-family: Helvetica;
              font-size: 14px;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #ffffff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .name {
              min-height: 17px;
              font-family: Helvetica;
              font-size: 15px;
              font-weight: bold;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #ffffff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .desc {
            margin-bottom: 30px;
            margin-right: 30px;
            width: 550px;
            font-family: Helvetica;
            font-size: 40px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #ffffff;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
          }

          .nftcount {
            margin-right: -44px;
            .num {
              font-family: Helvetica;
              font-weight: bold;
              font-size: 15px;
              color: #ffffff;
            }

            .sub {
              font-family: Helvetica;
              font-weight: bold;
              font-size: 15px;
              color: #ffffff;
            }
          }
          .explain {
            width: 110px;
            height: 30px;
            background-color: #270645;
            border-radius: 3px;
            position: absolute;
            top: 55px;
            right: 80px;
            span {
              font-family: Helvetica;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 15px;
              letter-spacing: 0px;
              color: #ffffff;
              margin-left: 13px;
              margin-right: 13px;
            }
          }
          .explains {
            width: 140px;
							height: 85px;
            background-color: #270645;
            position: absolute;
            top: 55px;
            right: 10px;
            line-height: 34px;
							text-align: left;
							z-index: 99;
							background-blend-mode: normal, normal;
							box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 9%);
							border-radius: 5px;
            .hideNfts {
              font-family: Helvetica;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 15px;
              letter-spacing: 0px;
              color: #ffffff;
             
								margin: 10px;
								cursor: pointer;
					
            }
          }
          .appealexplain {
            width: 185px;
            height: 56px;
            background-color: #270645;
            border-radius: 3px;
            position: absolute;
            top: 53px;
            right: 133px;
            span {
              font-family: Helvetica;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 15px;
              letter-spacing: 0px;
              color: #ffffff;
              margin-left: 13px;
              margin-right: 13px;
            }
          }
          .contractexplain {
            width: 150px;
            height: 30px;
            background-color: #270645;
            border-radius: 3px;
            position: absolute;
            top: 53px;
            right: 133px;
            span {
              font-family: Helvetica;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 15px;
              letter-spacing: 0px;
              color: #ffffff;
              margin-left: 13px;
              margin-right: 13px;
            }
          }
          .explainCollect {
            width: 185px;
            height: 56px;
            background-color: #270645;
            border-radius: 3px;
            position: absolute;
            top: 275px;
            right: 30px;
            span {
              font-family: Helvetica;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 15px;
              letter-spacing: 0px;
              color: #ffffff;
              margin-left: 13px;
              margin-right: 13px;
            }
          }
        }
      }
    }
  }

  .tit {
    text-align: center;
    margin-bottom: 29px;

    p {
      min-height: 100px;
      margin: 29px auto 0;
      max-width: 1018px;
      min-height: 0px;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
  }

  .share {
    width: 166px;
    height: 161px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#6f58d9, #6f58d9);
    background-blend-mode: normal, normal;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 20000;

    img,
    .icon {
      display: inline-block;
      width: 25px;
      height: 25px;
    }

    span {
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
  }
  .totalbox {
    // max-width: 1600px;
    height: 90px;
    background-color: #270645;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    line-height: var(--footer-height);
    z-index: floor($number: 999);
    //margin-left: -108px;
    .check {
      position: absolute;
      left: 106px;
      bottom: 35px;
      &::v-deep .v-input__control .v-input__slot .v-label {
        font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        // line-height: 54px;
        letter-spacing: 0px;
        color: #ffffff;
      }
      &::v-deep
        .v-input__control
        .v-input__slot
        .v-input--selection-controls__input
        .theme--light {
        color: #ffffff;
      }
    }
    p {
      margin-bottom: 0;
      margin-right: 40px;
      color: #ffffff;
    }
    .subcance3l {
      width: 75px;
      height: 36px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-style: solid;
      border-width: 2px;
      background-color: #7800f4;
      border-image-slice: 1;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #270645;
    }
    .btnbox {
      width: 75px;
      height: 36px;
      box-sizing: border-box;
      padding: 2px;
      background-color: #7800f4;
      border-radius: 17px;

      .subcancel {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 17px;
        background: #fff;
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0;
        color: #270645;
      }
    }
    .subsure {
      width: 75px;
      height: 36px;
      background-color: #7800f4;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
      border-radius: 18px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #ffffff;
      margin-right: 110px;
    }
  }

  .right_bottom {
    position: fixed;
    bottom: 120px;
    right: 50px;
    z-index:200;

    img {
      display: block;
      width: 59px;
      height: 59px;
      margin-top: 35px;
    }
  }

  .cards-container {
    .search_create {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .clearmode {
        margin-left: 10px;

        .checkbox {
          display: flex;
          align-items: center;
        }

        .content {
          display: flex;
          align-items: center;
          margin-right: 40px;
          height: 83px;
          font-size: 14px;
          font-weight: bold;
        }
        
        .image {
          display: flex;
          align-items: center;
          cursor: pointer;
          .nft {
            width: 22px;
            height: 28px;
          }

          .collection {
            width: 25px;
            height: 25px;
            margin-left: 30px;
          }
          .top_tab {
            .btn {
              height: 25px;
              font-family: Helvetica;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              line-height: 40px;
              letter-spacing: 0px;
              color: #766983;
              cursor: pointer;
            }
            .btn2 {
              height: 25px;
              font-family: Helvetica;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              line-height: 40px;
              letter-spacing: 0px;
              color: #7800f4;
              border-bottom: 3px solid #7800f4;
              padding-bottom: 8px;
            }
          }
        }
      }

      .create {
        &::v-deep .sort-select .rd.v-chip {
          box-shadow: none;
          border: none;
          margin-bottom: 2px;
          font-weight: bold;
        }
  .subOnsale {
          cursor: pointer;
          width: 135px;
          height: 41px;
          background-color: #f7f7f7;
          border-radius: 20px;

          .titleName {
            font-family: Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 35px;
            letter-spacing: 0px;
            color: #270645;
            margin-left: 30px;
          }
          .titleName::before {
            content: "";
            background-image: url(../../assets/icons/multi_list.png);
            width: 20px;
            height: 20px;
            background-size: 100%;
            display: inline-block;
            position: absolute;
            margin-top: 6px;
            margin-left: -30px;
          }
          .titleoffSale {
            font-family: Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 35px;
            letter-spacing: 0px;
            color: #270645;
            margin-left: 30px;
          }
          .titleoffSale::before {
            content: "";
            background-image: url(../../assets/icons/multi_unlist.png);
            width: 20px;
            height: 20px;
            background-size: 100%;
            display: inline-block;
            position: absolute;
            margin-top: 6px;
            margin-left: -30px;
          }
          .titleburn {
            font-family: Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 35px;
            letter-spacing: 0px;
            color: #270645;
            margin-left: 30px;
          }
          .titleburn::before {
            content: "";
            background-image: url(../../assets/icons/multi_burn.png);
            width: 20px;
            height: 20px;
            background-size: 100%;
            display: inline-block;
            position: absolute;
            margin-top: 6px;
            margin-left: -30px;
          }

          &:hover {
            border: solid 2px #7800f4;
          }
        }

        .owner {
          display: flex;
          align-items: center;

          font-size: 14px;
          font-weight: bold;
          margin-top: 13px;

          span {
            color: #7800f4;
          }
        }
        .refresh {
          height: 18px;
          width: 18px;
          margin-top: 16px;
          margin-left: 15px;
        }

        .go {
          transform: rotate(360deg);
          transition: all 2s;
          pointer-events: none;
          user-select: none;
        }
      }
    }

    .cardlist {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .check {
        // z-index: 200;
        position: relative;
        top: -370px;
        left: 10px;
        &::v-deep .v-input__control .v-input__slot .v-label {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          // line-height: 54px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .ownerCheck {
        position: relative;
        top: -285px;
        left: 10px;
        &::v-deep .v-input__control .v-input__slot .v-label {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          // line-height: 54px;
          letter-spacing: 0px;
          color: #270645;
        }
      }

      .v-card {
        margin-right: 23px;
        margin-bottom: 30px;

        &:nth-child(5n) {
          margin-right: 0;
        }
      }

      .v-card.mobile {
        width: 100%;
        margin-right: 0;
      }

      .empty {
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &.mobile {
    width: 100%;

    .card {
      width: 100%;
      margin-right: unset;
      justify-content: space-between;
    }

    .cards-container {
      margin-right: unset;
      .search_create {
        display: flex;
        flex-direction: column !important;
        .clearmode {
          display: flex;
          flex-direction: column !important;
          .buttons {
            .subOnsale {
              width: 115px;
            }
          }
        }
        .create {
          margin-top: 10px;
        }
      }
    }

    .v-card {
      margin-right: 10px !important;
      width: 155px;
    }

    .img {
      margin-right: unset !important;
    }

    .tit {
      margin-bottom: 20px;
    }

    .search_create {
      margin-bottom: 20px;
    }

    .desc {
      font-size: 30px !important;
     width: 100% !important;
      word-break: keep-all !important ;
      word-wrap: break-word; 
    }

    .createdby {
      margin-left: 15px !important;
    }
    .top {
      .topbox {
        .right {
          width:100%;
          .box {
          min-width: 0px;
							margin: 40px 25px;
          }
        }
      }
    }
    .totalbox {
      .subsure {
        margin-right: 10px;
      }
      p {
        margin-right: 10px;
      }
      .check {
        position: absolute;
        left: 0px;
        bottom: 33px;
      }
    }
  }
}
</style>
