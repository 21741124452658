<template>
  <v-text-field
    class="search-inpt1"
    :value="searchInput"
    @input="change"
    hide-details
    hide-spin-buttons
  >
    <template v-slot:prepend-inner
      ><img class="prepend-inner" src="@/assets/icons/search.png" alt
    /></template>
  </v-text-field>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "searchInput",
  computed: {
    ...mapState({
      searchInput: (state) => state.searchInput,
    }),
    
  },
  watch: {
      // show: {
      //     handler: function() {
      //         this.$store.dispatch("setSearchInput", '');
      //     },
      //     immediate: true
      // }
  },
  mounted(){
  this.$store.dispatch("setSearchInput", '');
  },
  methods: {
    change: function (v) {
      this.$emit("change", v);
      this.$store.dispatch("setSearchInput", v);
    },
  },
};
</script>

<style lang="scss">
.search-inpt1 {
     align-self: center;
     margin-bottom: 30px;
  .prepend-inner {
    width: 17px;
    height: auto;
  }
  &.v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after {
       content: none!important;
  }
  .v-input__control {
    .v-input__slot::before {
      content: none;
    }
  }
  .v-input__slot {
    width: 200px;
    height: 40px;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 20px;
    outline-color: #f0f0f0;
    .v-input__prepend-inner {
      align-self: center;
      margin-top: 0;
      margin-left: 14px;
      margin-right: 13px;
      padding-right: 0;
    }
    .v-text-field__slot {
      flex: 1;
      padding-right: 20px;
      width: unset;
      input {
        width: auto;
      }
    }
  }
}
</style>
